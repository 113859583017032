input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.goalBox {
  margin: auto;
  min-width: 100%;
  max-width: 100%;
  height: 85%;
  // border: 1px solid yellowgreen;
  // border-radius: 5px;
}

.tabs {
  width: 50%;
}

.Mui-selected {
  background-color: lightblue !important;
  color: black !important;
}

.text {
  font-weight: bold;
  text-align: center;
  margin: 12px 0;
  color: #fff !important;
}

.priceBox__container {
  // border: 1px solid gray;
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-height: 500px;

  // gap: 1.5rem;
  gap: 0.5rem;
}

.priceBox__wrappper {
  max-width: 140px;
  // max-height: 80px;
  // height: 80px;
  border-radius: 5px;
  border: 1px solid skyblue;
  cursor: pointer;
  position: relative;
  transition: 0.5s all;
}

.priceBox__wrappper:hover {
  background-color: rgb(217 220 240);
}

.active-goal {
  background-color: rgb(217 220 240);
  border: 1px solid blue;
  color: #3f51b5;
  opacity: 1;
}

.checkicon {
  position: absolute;
  right: 1px;
  top: 1px;
}

.price___item {
  height: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.price___item p {
  font-weight: bold;
  font-size: 0.8em;
}


@media screen and (max-width: "990px") {
  .priceBox__wrappper {
    text-align: center;
    vertical-align: middle;
    max-height: 40px;
  }

  .priceBox__container {
    margin: 0px;
    grid-gap: 1rem;
  }

  .price___item p {
    font-size: 1em;
    padding-top: 10px;
  }

  .checkicon {
    position: absolute;
    right: -2px;
    top: -6px;
  }

  .setGoalButton {
    width: 140px;
  }
}

@media screen and (max-width: "440px") {
  .price___item {
    height: 50%;
  }

  .price___item p {
    font-size: 0.7em;
    padding-top: 35px;
  }

  .checkicon {
    position: absolute;
    right: -2px;
    top: -13px;
  }

  .setGoalButton {
    width: 140px !important;
  }

  .amountBox1 {
    padding-left: 5px;
    margin-top: 15px;
    position: relative;
  }

  .amountBox1 input {
    padding-left: 45px;
    width: 98%;
    height: 50px;
  }
}

@media screen and (max-width: "320px") {
  .price___item {
    height: 7%;
  }

  .price___item p {
    font-size: 0.9em;
    padding-top: 30px;
  }

  .amount {
    margin: 1px;
    margin-top: 20px;
    margin-left: -1px;
  }

  .card {
    width: 240px;
    margin-left: -40px;
  }
}

@media screen and (max-width: "768px") {
  .price___item p {
    padding: 30px;
  }
}

@media screen and (max-width: "1024px") {
  .price___item p {
    padding-top: 20px;
  }
}

@media screen and (max-width: "1440px") {
  .price___item p {
    margin-bottom: 1px;
  }
}