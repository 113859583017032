.MuiTableCell-paddingCheckbox {
    padding: 16px !important;
}

.css-11w94w9-MuiTableCell-root>div {
    justify-content: center;
}

.MuiTablePagination-toolbar .jss2 {
    width: 100%, !important                     ;
}

.jss2 {
    width: 100% !important;
    height: 100% !important;
}

.jss1 {
    border-radius: 0% !important;
    width: auto !important;
    height: auto !important;
    background-color: transparent !important;
}

.css-1ib0mpw-MuiDrawer-docked>div {
    z-index: -1 !important;
    position: fixed;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-spacing-xs-2.MuiGrid-direction-xs-column.MuiGrid-grid-xs-true.grid.css-137psqw-MuiGrid-root {
    padding-left: 57px;
    padding-top: 56px;
}

.css-1rjh7xg {
    box-shadow: none !important;
}

.css-1dcyink {
    box-shadow: none !important;
}


.css-1lqu4no-MuiPaper-root-MuiCard-root {
    box-shadow: none !important;
}

.css-cm6sdv-MuiPaper-root-MuiCard-root {
    box-shadow: none !important;
}

/* .react-grid-item.cssTransforms {
    background-color: #fafafa !important;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.react-grid-item.cssTransforms .adminDashboardCard {
    background-color: #fafafa !important;
} */

.css-1wcaknn {
    padding-top: 0px !important
}

.css-5cs5ot-MuiDrawer-docked>div {
    z-index: -1 !important;
}

.css-4kimcm-MuiPaper-root-MuiCard-root{
    box-shadow: none !important;
}

.swal2-container {
    z-index: 12001 !important;
}


#my-material-table .MuiTableHead-root {
    position: static;
    top: 0;
    /* background-color: white; */
    z-index: 1;
}

::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: inherit;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: grey;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}